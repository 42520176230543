import createReactComponent from '../createReactComponent';

const Languages = createReactComponent('Languages', [
  ['path', { d: 'm5 8 6 6', key: '1wu5hv' }],
  ['path', { d: 'm4 14 6-6 2-3', key: '1k1g8d' }],
  ['path', { d: 'M2 5h12', key: 'or177f' }],
  ['path', { d: 'M7 2h1', key: '1t2jsx' }],
  ['path', { d: 'm22 22-5-10-5 10', key: 'don7ne' }],
  ['path', { d: 'M14 18h6', key: '1m8k6r' }],
]);

export default Languages;
